import React from "react";
import { InitialFeeWithPerExecutionThresholds } from "../types";

export const InitialFeeWithPerExecutionThresholdsView = ({
  model,
  validFrom,
}: {
  model: InitialFeeWithPerExecutionThresholds | undefined;
  validFrom: string;
}) => {
  if (model) {
    return (
      <table>
        <tbody>
          <tr>
            <th style={{ textAlign: "right" }}>Valid From:</th>
            <td style={{ textAlign: "left" }}>{validFrom}</td>
          </tr>
          <tr>
            <th style={{ textAlign: "right" }}>Currency:</th>
            <td>{model.currency}</td>
          </tr>
          <tr>
            <th style={{ textAlign: "right" }}>Initial fee:</th>
            <td>{model.initialFee}</td>
          </tr>
          <tr>
            <th></th>
            <td>
              <table>
                <tr>
                  <th>Breakpoint</th>
                  <th>Price per execution</th>
                </tr>
                {model?.thresholds?.map((t, idx) => (
                  <tr key={idx}>
                    <td>{t.from}</td>
                    <td>{t.perExecution}</td>
                  </tr>
                ))}
              </table>
            </td>
          </tr>
        </tbody>
      </table>
    );
  }
};
