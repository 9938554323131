import { LoadingButton } from '@mui/lab'
import { Alert, Box, Collapse, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material'
import React, { useEffect, useState } from 'react'
import CustomButton from './button'
import UpdateIcon from '@mui/icons-material/Update';
import CloseIcon from '@mui/icons-material/Close'
import { padding } from '../../theme';

type ModalProps = ({
  title: string,
  updatingFailedMessage: string,
  children?: React.ReactNode | null,
  open: boolean,
  fullScreen?: boolean,
  closeModal: Function,
  onSave: React.MouseEventHandler<HTMLButtonElement>,
 //updating: updatingReducer | string ,
  updatingDisabled?: boolean
})

export const UpdateModal = ({
  title,
  updatingFailedMessage,
  children,
  open,
  closeModal,
  fullScreen,
  onSave,
  //updating,
  updatingDisabled=false
}: ModalProps) => {
  const [attemptedToSave, setAttemptedToSave] = useState(false)
  const [updatingFailed, setupdatingFailed] = useState(false)

  return (
    <Dialog open={open} fullScreen={fullScreen}>
      <DialogTitle>{title}</DialogTitle>

      <DialogContent>
        <UpdatingFailedAlert updatingFailedMessage={updatingFailedMessage} show={updatingFailed} onClose={() => { setupdatingFailed(false); }} />
        {children}
      </DialogContent>

      <DialogActions>
        <CustomButton outlined onClick={(event) => {
          setupdatingFailed(false)
          setAttemptedToSave(false)
          closeModal()
        }}>Cancel</CustomButton>
        <LoadingButton
          disabled={updatingDisabled}
          loading={false }
          onClick={(event) => {
            setAttemptedToSave(true)
            onSave(event)
            closeModal()
          }}
          loadingPosition="start"
          startIcon={<UpdateIcon />}
          variant="contained">
          Update
        </LoadingButton>
      </DialogActions>
    </Dialog>
  )
}

export function UpdatingFailedAlert({ show, onClose, updatingFailedMessage }) {
  return (
    <Collapse in={show}>
      <Alert
        severity="error"
        action={
          <IconButton
            aria-label="close"
            color="inherit"
            size="small"
            onClick={onClose}
          >
            <CloseIcon fontSize="inherit" />
          </IconButton>
        }
        sx={{ mb: 2 }}
      >
        {updatingFailedMessage}
      </Alert>
    </Collapse>
  )
}

export const Subheader = ({ title, noPadding = false }) => {
  return (
    <Box style={{
      paddingTop: noPadding ? 'auto' : padding * 3
    }}>
      <DialogContentText>{title}</DialogContentText>
    </Box>
  )
}