export const compareVersions = (a, b) =>  {
    let aParts
    let bParts
    if(a.softwareVersion && b.softwareVersion){
      aParts = a.softwareVersion?.split('.').map(Number);
      bParts = b.softwareVersion?.split('.').map(Number);
    }else{
      aParts = a.split('.').map(Number);
      bParts = b.split('.').map(Number);
    }
  
  
    for (let i = 0; i < Math.max(aParts.length, bParts.length); i++) {
        const aVal = aParts[i] || 0;  // Default to 0 if part is missing
        const bVal = bParts[i] || 0;
        if (aVal !== bVal) {
            return aVal - bVal;
        }
    }
    return 0; 
  }

  export const isVersionHigherOrEqual = (version1: string, version2: string): boolean =>  {
    const parts1 = version1.split('.').map(Number);
    const parts2 = version2.split('.').map(Number);

    // Compare each part of the versions
    for (let i = 0; i < Math.max(parts1.length, parts2.length); i++) {
        const v1 = parts1[i] || 0; // Default to 0 if part is missing
        const v2 = parts2[i] || 0;

        if (v1 > v2) return true;
        if (v1 < v2) return false;
    }

    // If all parts are equal
    return true;
}
