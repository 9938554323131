import { useCallback, useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { AnyAction, Dispatch } from "redux";
import { DataLoadingText } from "../../components/atoms/text/dataLoadingText";
import Page from "../../components/molecules/page";
import { fetchMachines } from "../../state/actions/machine";
import { AppDispatch, StoreState } from "../../state/configureStore";
import React from "react";
import { Dvr, Edit, Info, ReceiptLongOutlined } from "@mui/icons-material";
import { Box } from "@mui/material";
import EditMachineModal from "./editMachineModal";
import LogTable from "./logTable";
import InteractiveMap from "../../components/molecules/maps/interactive/interactiveMap";
import { padding, text_colors } from "../../theme";
import { TextTitle } from "../../components/atoms/text/titles";
import { MachineState } from "../../state/reducers/machine";
import UptimeTable from "./uptimeTable";
import { MachineDetails, MachineDetailsView } from "./machineDetails";
import MachineErrorList from "./machineErrorList";
import { RecipesList } from "./recipes/recipes-list";
import { SoldRecipes } from "../customers/customer";
import GppGoodIcon from "@mui/icons-material/GppGood";
import GppBadIcon from "@mui/icons-material/GppBad";
import {
  deactivateMachine,
  setMachineAsActive,
} from "../../state/thunks/machines";
import { updateReducerWithThunk } from "../../state/reducerUtils";
import CustomButton from "../../components/molecules/button";
import { isVersionHigherOrEqual } from "../customers/machineGraph/softwareVersionUtils";
import { FetchFromBackoffice } from "../../utils/request";
import { Text } from "recharts";
import VerificationModal from "./verificationModal";

const infoRowWidth = 800;
const style = {
  infoSection: {
    paddingBottom: padding * 2,
    maxWidth: infoRowWidth,
  },
  paddingTop: {
    paddingTop: padding * 2,
  },
  infoRow: {
    display: "flex",
    flexDirection: "row" as "row",
    justifyContent: "space-between",
  },
};

export const Machine = (
  state: ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>
) => {
  const { serialId } = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const [editModalOpen, setEditModalOpen] = useState<boolean>(false);
  const [verifiUpdate, setVerifiUpdate] = useState<boolean>(false);
  const [machine, setMachine] = useState<MachineState | undefined>(undefined);
  const [details, setDetails] = useState<MachineDetails>()
  const [updateIssueInfomration, setUpdateIssueInformation] = useState<string>()
  const [hasUpdateIssue, setHasUpdateIssue] = useState<boolean>(true)

  useEffect(()=>{
      if(serialId){
          FetchFromBackoffice<MachineDetails>(`machines/${serialId}`)
              .then(data => {
                  setDetails(data)
              })
      }
  }, [serialId])

  useEffect( () => {
    if (machine?.remoteUpdateEnabled) {
      setHasUpdateIssue(false);
    } else {
      setHasUpdateIssue(true);
      setUpdateIssueInformation(
        "Ensure your software version is higher than 0.5.0 to qualify for updates. For assistance, please contact Seerefine"
      );
    }
  }, [setHasUpdateIssue, machine?.remoteUpdateEnabled])

  useEffect(() => {
    setMachine(state.machines.find((machine) => machine.serialId === serialId));
  }, [serialId, JSON.stringify(state.machines)]);

  useEffect(() => {
    // this can be the case if we got here via refresh or direct link
    const machineNotFetched =
      state.machines.findIndex((d) => d.serialId === serialId) === -1;
    if (machineNotFetched) state.fetchMachines();
  }, [serialId]);

  useEffect(() => {
    if (state.savingState === "ready") setEditModalOpen(false);
  }, [state.savingState]);

  const LogsSection = useCallback(() => {
    return <LogTable serialId={serialId} />;
  }, [serialId]);

  const UptimeSection = useCallback(() => {
    return (
      <Box style={{ ...style.infoSection, ...style.paddingTop }}>
        <TextTitle title={"Uptime"} />
        <UptimeTable serialId={serialId} />
      </Box>
    );
  }, [serialId]);

  const MachineInfo = useCallback(() => {
    const ownerCompanyName = machine?.parents?.length
      ? machine?.parents[0].name
      : undefined;
    const ownerCompanyId = machine?.parents?.length
      ? machine?.parents[0].id
      : undefined;

    const nodeUrl =
      "/customers/" +
      (machine?.parents?.length
        ? machine?.parents.map((p) => p.id)?.join("/") + "/" + machine?.nodeId
        : "");
    return (
      <Box>
        {(!machine || !ownerCompanyName) && state.isLoading_machineInfoBox ? (
          <DataLoadingText
            showSpinner
            text={"Loading machine information ..."}
          />
        ) : (
          <React.Fragment>
            <Box style={style.infoSection}>
              <TextTitle title={"Overview"} />
              <MachineDetailsView
                nodeUrl={nodeUrl}
                nodeName={machine?.nodeName}
                ownerCompanyName={ownerCompanyName}
                ownerCompanyId={ownerCompanyId}
                MachineId={machine?.serialId}
              />
            </Box>
            <MachineErrorList SerialId={machine?.serialId ?? ""} />

              {machine?.location ? (
                            <Box style={{ ...style.infoSection, ...style.paddingTop }}>
              <TextTitle title={"Location"} />
                <Box
                  style={{
                    width: "100%",
                    height: 300,
                    display: "flex",
                    alignSelf: "center",
                  }}
                >
                  <InteractiveMap locations={[machine.location]} />
                </Box>
                </Box>
              ) : null}
            <Box sx={{flex: 1, flexDirection: "column", width: 400}}>
            {
              hasUpdateIssue ?

              <Box sx={{marginBottom: 2}}> 
                <Text style={{width: 200 }}>{updateIssueInfomration }</Text>
              </Box>: <></>
            }
            <CustomButton disabled={hasUpdateIssue} onClick={() => setVerifiUpdate(true)}>
              Update machine
            </CustomButton>
            </Box>
          </React.Fragment>
        )}
      </Box>
    );
  }, [machine, state.isLoading_machineInfoBoxm, hasUpdateIssue]);

  return (
    <React.Fragment>
      {machine && editModalOpen ? (
        <EditMachineModal
          machine={machine}
          open={editModalOpen}
          closeModal={() => setEditModalOpen(false)}
        />
      ) : null}
      {machine && verifiUpdate ? (
        <VerificationModal
          details={details}
          open={verifiUpdate}
          closeModal={() => setVerifiUpdate(false)}
        />
      ) : null}
      <Page
        sideScroll
        title={
          <div
            style={{
              flexDirection: "row",
              display: "flex",
            }}
          >
            <div
              style={{
                marginRight: "5px",
              }}
            >
              {machine?.monitored ? (
                <GppGoodIcon
                  fontSize={"large"}
                  sx={{ color: text_colors.greyBlue, marginBottom: "-4px" }}
                />
              ) : (
                <GppBadIcon
                  fontSize={"large"}
                  sx={{ color: text_colors.greyDark, marginBottom: "-4px" }}
                />
              )}
            </div>
            {"Machine: " + machine?.name ? machine?.name : machine?.serialId}
          </div>
        }
        Menu={{
          Id: "machine-page-menu",
          ButtonTitle: "Actions",
          MenuItems: [
            {
              Label: "Edit machine name",
              Icon: <Edit />,
              onClick: () => setEditModalOpen(true),
            },
            {
              toolTip: machine?.nodeName
                ? undefined
                : "Machine need to be connected to store to be activated",
              Enabled: machine?.nodeName !== undefined,
              Label: machine?.monitored
                ? "Deactivate Machine"
                : "Activate Machine",
              Icon: machine?.monitored ? (
                <GppBadIcon
                  sx={{ color: text_colors.greyDark, marginBottom: "-5px" }}
                />
              ) : (
                <div>
                  <GppGoodIcon
                    sx={{ color: text_colors.greyBlue, marginBottom: "-5px" }}
                  />
                </div>
              ),
              onClick: () => {
                if (machine?.serialId) {
                  if (machine?.monitored) {
                    dispatch(
                      updateReducerWithThunk({
                        payload: {
                          serialNumber: machine.serialId,
                        },
                        type: "MACHINE_DEACTIVATED",
                      })
                    );
                    dispatch(
                      deactivateMachine({
                        serialNumber: machine.serialId,
                      })
                    );
                  } else {
                    dispatch(
                      updateReducerWithThunk({
                        payload: {
                          serialNumber: machine.serialId,
                        },
                        type: "MACHINE_ACTIVATED",
                      })
                    );
                    dispatch(
                      setMachineAsActive({ serialNumber: machine.serialId })
                    );
                  }
                }
              },
            },
          ],
        }}
        tabs={[
          {
            name: "Overview",
            icon: <Info />,
            pageSections: [
              <MachineInfo />,
              <SoldRecipes
                id={serialId}
                queryString={"recipes-run-daily-machine"}
              />,
              <UptimeSection />,
            ],
          },
          {
            name: "Sales",
            icon: <ReceiptLongOutlined />,
            pageSections: [<RecipesList MachineId={serialId} />],
          },
          {
            name: "Machine Logs",
            icon: <Dvr />,
            pageSections: [<LogsSection />],
          },
        ]}
      />
    </React.Fragment>
  );
};

const mapStateToProps = (state: StoreState) => ({
  machines: state.machines,
  isLoading_machineInfoBox: state.machine_fetching === "loading",
  savingState: state.machine_saving,
  customers: state.customers,
});

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  fetchMachines: () => dispatch(fetchMachines()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Machine);
