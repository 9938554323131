import React, { useEffect, useState } from "react"
import { FetchFromBackoffice } from "../../utils/request"
import { FiberManualRecord } from '@mui/icons-material';
import { PropertyView } from "../../components/molecules/properties-view";
import { Box, Link, Typography } from "@mui/material";
import { text_colors } from "../../theme";
import { useParams, Link as RouterLink } from 'react-router-dom'

const capitalize = s => (s && s[0].toUpperCase() + s.slice(1)) || ""

type Reported = {
    softwareVersion?:string,
    liquidState?: "good" | "low" | "empty" | undefined,
}

export type MachineDetails = {
    id:string,
    machineName:string,
    serialNumber:string,
    deviceId:string,
    reported?: Reported,
    connectionState:string,
    cyclesCount:number,
}

export const LiquidLevelView = ({value}:ValueViewProps) => {
    let color = {}

    switch(value) {
        case "good":
            color = {color: text_colors.green}
            break;
        
        case "low":
            color = {color: text_colors.yellow}
            break;

        case "empty":
            color = {color: text_colors.red }
            break;

        default:
            color = {color: "white"}
    }

    return (
        <Box sx={{marginLeft: "-4px", display: 'flex', alignItems: 'center'}}>
            <FiberManualRecord style={color} />
            <Typography sx={{paddingLeft: "4px", paddingTop: "1px"}} color='textPrimary'>
                {capitalize(value)}
            </Typography>
        </Box>
    )
}

export const ConnectionState = ({value}:ValueViewProps) => {
    let color = {}

    switch(value) {
        case "Connected":
            color = {color: text_colors.green}
            break;
        
        case "Disconnected":
            color = {color: text_colors.red}
            break;

        default:
            color = {color: "white"}
    }

    return (
        <Box sx={{marginLeft: "-4px", display: 'flex', alignItems: 'center'}}>
            <FiberManualRecord style={color} />
            <Typography sx={{paddingLeft: "4px", paddingTop: "1px"}} color='textPrimary'>
                {capitalize(value)}
            </Typography>
        </Box>
    )
}


export const MachineDetailsView = ({
  MachineId,
  ownerCompanyName,
  ownerCompanyId,
  nodeName,
  nodeUrl
}:{
  MachineId: string | undefined,
  ownerCompanyName: string | undefined,
  ownerCompanyId: string | undefined,
  nodeName: string | undefined,
  nodeUrl: string | undefined

}) : React.ReactElement => {
    const [details, setDetails] = useState<MachineDetails|undefined>()

    useEffect(()=>{
        if(MachineId){
            FetchFromBackoffice<MachineDetails>(`machines/${MachineId}`)
                .then(data => {
                    setDetails(data)
                })
        }
    }, [MachineId])

    const owner = (ownerCompanyName && ownerCompanyId) ? <Link style={{ flex: 1 }} component={RouterLink} to={`/customers/${ownerCompanyId}`}>{ownerCompanyName}</Link> : <Typography style={{ flex: 1 }} variant='body1'>{'Nobody'}</Typography>
    const store = (nodeName ? <Link sx={{ flex: 1 }} component={RouterLink} to={nodeUrl}>{nodeName}</Link> : <Typography style={{ flex: 1 }} variant='body1'>{'Not in any store'}</Typography>)

    return (
        <PropertyView 
            Values={[
                {Label: 'Owner:', ValueComponent: owner},
                {Label: 'Store:', ValueComponent: store},
                {Label: "Total run cycles over lifetime:", Path: "cyclesCount" },
                {Label: "Connection state:", Path: "connectionState", CustomComponent: ConnectionState},
                {Label: "Serial Id:", Path: "serialNumber"},
                {Label: "SW Serial Id:", Path: "deviceId"},
                {Label: "SW Version:", Path:"reported.softwareVersion"},
                {Label: "Liquid level:", Path:"reported.liquidState", CustomComponent: LiquidLevelView},
            ]}
            Data={details}
        />
    )
}
