import React, { useEffect, useState } from "react";
import { AddModal } from "../../../../components/molecules/AddModal";
import {
  InitialFeeWithPerExecutionThresholdsCommand,
  Threshold,
} from "../types";
import { MonthSelectorInput } from "../../../../components/atoms/inputs";
import {
  FlexContainerColumn,
  FlexContainerRow,
} from "../../../../components/atoms/layouts";
import { isValid } from "../../../../components/molecules/validatable-inputs/functions";
import { ValidatableSelectInput } from "../../../../components/molecules/validatable-inputs/validatableSelectInput";
import { ValidatableNumberInput } from "../../../../components/molecules/validatable-inputs";
import {
  CommandWithSchema,
  Schema,
} from "../../../../common-types/commandingTypes";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";

import { IconButton } from "@material-ui/core";
import CustomButton from "../../../../components/molecules/button";

export const InitalFeeWithPerExecutionEditCommmandModal = ({
  command,
  onClose,
  onSave,
}: {
  command: CommandWithSchema<InitialFeeWithPerExecutionThresholdsCommand>;
  onClose: () => void;
  onSave: (command: InitialFeeWithPerExecutionThresholdsCommand) => void;
}) => {
  const [editCommand, setEditCommand] =
    useState<InitialFeeWithPerExecutionThresholdsCommand>(command.command);
  const [validationError, setValidationError] = useState<boolean>(true);
  const schema: Schema<InitialFeeWithPerExecutionThresholdsCommand> = {
    required: ["currency", "id", "initialFee", "validFrom"],
    type: "object",
    properties: {
      id: {
        minLength: 1,
        type: "string",
        title: "Id",
      },
      validFrom: {
        type: "string",
        title: "From Date",
      },
      currency: {
        enum: ["SEK", "NOK", "EUR"],
        type: "string",
        title: "Currency",
      },
      initialFee: {
        type: "number",
        title: "Initial Fee",
      },
      thresholds: {
        type: "array",
        title: "Thresholds",
        items: {
          required: ["from", "perExecution"],
          type: "object",
          properties: {
            from: {
              type: "integer",
            },
            perExecution: {
              type: "number",
            },
          },
        },
        nullable: true,
      },
    },
  };

  useEffect(() => {
    if (editCommand) {
      setValidationError(!isValid(schema, editCommand));
    }
  }, [editCommand]);

  const removeThreshold = (index: number) => {
    setEditCommand((c) => ({
      ...c,
      thresholds: [...c.thresholds.filter((_, idx) => idx !== index)],
    }));
  };

  return (
    <AddModal
      title="Add Price model"
      open={true}
      onSave={() => onSave(editCommand)}
      closeModal={onClose}
      saving="Saving"
      savingFailedMessage="Failed"
      savingDisabled={validationError}
    >
      <ValidatableSelectInput
        title="Currency"
        command={editCommand}
        property="currency"
        schema={schema}
        update={setEditCommand}
      />
      <MonthSelectorInput
        label="Start Date"
        value={editCommand.validFrom}
        minDate={editCommand.validFrom}
        onChange={(v) => setEditCommand((c) => ({ ...c, validFrom: v }))}
        required={true}
      />
      <ValidatableNumberInput
        title="Initial Fee"
        command={editCommand}
        property="initialFee"
        schema={schema}
        update={setEditCommand}
      />
      <ThresholdsControl
        schema={schema.properties.thresholds?.items}
        thresholds={editCommand.thresholds}
        onChange={(v) =>
          setEditCommand((c) => ({
            ...c,
            thresholds: c.thresholds.map((t, i) =>
              i === v.Index ? v.Threshold : t
            ),
          }))
        }
        removeThreshold={(idx) => removeThreshold(idx)}
      />
      <CustomButton
        noBg
        outlined
        disabled={
          editCommand.thresholds[editCommand.thresholds.length - 1]?.from ===
            null ||
          editCommand.thresholds[editCommand.thresholds.length - 1]
            ?.perExecution === null
        }
        onClick={() =>
          setEditCommand((c) => ({
            ...c,
            thresholds: [
              ...c.thresholds,
              { $schemaId: "Threshold", from: null, perExecution: null },
            ],
          }))
        }
      >
        + Add New
      </CustomButton>
    </AddModal>
  );
};

const ThresholdsControl = ({
  thresholds,
  schema,
  onChange,
  removeThreshold,
}: {
  thresholds: Array<Threshold>;
  schema: Schema<Threshold>;
  onChange: (v: { Index: number; Threshold: Threshold }) => void;
  removeThreshold: (index: number) => void;
}) => {
  return thresholds.map((t, idx) => (
    <ThresholdControl
      key={idx}
      index={idx}
      threshold={t}
      schema={schema}
      onChange={onChange}
      removeThreshold={(idx) => removeThreshold(idx)}
    />
  ));
};

const ThresholdControl = ({
  threshold,
  index,
  schema,
  onChange,
  removeThreshold,
}: {
  threshold: Threshold;
  schema: Schema<Threshold>;
  index: number;
  onChange: (v: { Index: number; Threshold: Threshold }) => void;
  removeThreshold: (index: number) => void;
}) => {
  const [command, setCommand] = useState<Threshold>(threshold);

  useEffect(() => {
    onChange({ Index: index, Threshold: command });
  }, [command]);

  return (
    <FlexContainerColumn>
      <FlexContainerRow>
        <ValidatableNumberInput
          title="From"
          command={command}
          property="from"
          schema={schema}
          update={setCommand}
        />
        <ValidatableNumberInput
          title="Price per Execution"
          command={command}
          property="perExecution"
          schema={schema}
          update={setCommand}
        />
        <IconButton onClick={() => removeThreshold(index)}>
          {" "}
          <DeleteForeverIcon />
        </IconButton>
      </FlexContainerRow>
    </FlexContainerColumn>
  );
};
