import React from 'react'
import { connect } from 'react-redux'
import { StoreState } from '../../state/configureStore'
import { AnyAction, Dispatch } from 'redux'
import { renameMachine } from '../../state/actions/machine'
import { UpdateModal } from '../../components/molecules/UpdateModal'
import { Text } from 'recharts'
import {  FetchFromUtilsConnect } from '../../utils/request'
import { MachineDetails } from './machineDetails'

type IComponentProps = IComponentOwnProps & IComponentStoreProps & IComponentDispatchProps;

type IComponentStoreProps = ReturnType<typeof mapStateToProps>;
type IComponentDispatchProps = ReturnType<typeof mapDispatchToProps>;

interface IComponentOwnProps {
  open: boolean;
  closeModal: Function;
  details: MachineDetails | undefined;
}

function VerificationModal(props: IComponentProps): React.ReactElement {


  const onVerify = () => {
    FetchFromUtilsConnect(`device/${props.details?.deviceId}/update/now`)

  }

  return (
    <UpdateModal
      title={'Update machine'}
      open={props.open}
      updatingFailedMessage={'Updateing failed'}
      closeModal={() => { props.closeModal() }} onSave={onVerify}>
      <Text> 
        This action will initiate an update cycle on the machine. 
        Ensure that the affected store is notified beforehand. 
        Please note that the process may take up to 30 minutes, during which the machine will be unavailable for use.  
      </Text>
    </UpdateModal>
  )
}

const mapStateToProps = (state: StoreState) => ({
  machine_saving: state.machine_saving
})

const mapDispatchToProps = (dispatch: Dispatch<AnyAction>) => ({
  renameMachine: (id: string, name: string) => dispatch(renameMachine(id, name))
})

export default connect(mapStateToProps, mapDispatchToProps)(VerificationModal)